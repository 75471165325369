.Hero{
    height: 100vh;
    background-image: url('../assets/hero.webp');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.Hero_Left{
    height: 100%;
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #00315bad;
}

.Hero_Left h2{
    margin: 0;
    margin-left: 17.5vw;
    margin-top: 70px;
    font-size: 30px;
    font-weight: 400;
    color: #fff;
    text-transform: uppercase;
}

.Hero_Left p{
    margin: 0;
    margin-top: 10px;
    font-size: 16px;
    color: #fff;
    margin-left: 17.5vw;
}

.Hero_Left h2 b{
    font-weight: 600;
}

.About{
    height: fit-content;
    display: flex;
    justify-content: center;
    background-color: #00305b;
}

.About span{
    width: 65%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 70px 0;
}

.About h2{
    margin: 0;
    color: #fff;
    font-size: 30px;
    font-weight: 400;
    margin-top: 70px;
}

.About h3{
    margin: 0;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    margin-top: 20px;
}

.About p{
    margin: 0;
    margin-top: 20px;
    color: #fff;
}

.About ol{
    text-align: left;
    margin: 0;
    padding: 0;
    margin-left: 15px;
}

.Vision{
    height: fit-content;
    display: flex;
    justify-content: center;
    background-color: #fff;
}

.Vision span{
    width: 65%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 70px 0;
}

.Vision h2{
    margin: 0;
    color: #00305b;
    font-size: 30px;
    font-weight: 400;
}

.Vision h3{
    margin: 0;
    color: #00305b;
    font-size: 16px;
    font-weight: 500;
    margin-top: 20px;
}

.Vision p{
    margin: 0;
    margin-top: 20px;
    color: #00305b;
}

.Vision ol{
    text-align: left;
    margin: 0;
    padding: 0;
    margin-left: 15px;
}

.Premier{
    height: fit-content;
    display: flex;
    justify-content: center;
    background-color: #00305b;
}

.Premier span{
    width: 65%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 70px 0;
}

.Premier h2{
    margin: 0;
    color: #fff;
    font-size: 30px;
    font-weight: 400;
}

.Premier h3{
    margin: 0;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    margin-top: 20px;
}

.Premier p{
    margin: 0;
    margin-top: 20px;
    color: #fff;
}

.Premier ol{
    text-align: left;
    margin: 0;
    padding: 0;
    margin-left: 15px;
}

.Introduction{
    height: auto;
    display: flex;
    justify-content: center;
    padding: 70px 0px;
    background-color: #00305b;
}

.Introduction span{
    width: 65%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Introduction h2{
    margin: 0;
    color: #fff;
    font-size: 30px;
    font-weight: 400;
}

.Introduction p{
    margin: 0;
    margin-top: 20px;
    color: #fff;
    text-align: center;
}

.Functions{
    height: auto;
    display: flex;
    background-color: #fff;
}

.Functions_Left{
    min-width: 40%;
    display: flex;
    background-color: #eaf4f6;
}

.Functions_Left h2{
    margin: 0;
    margin-left: 17.5vw;
    margin-top: 70px;
    font-size: 30px;
    font-weight: 400;
    color: #00305b;
    text-transform: uppercase;    
}

.Functions_Left h2 b{
    font-weight: 600;
}

.Functions_Right{
    flex: 1;
    display: flex;
    padding-top: 50px;
    padding-bottom: 70px;
}

.Functions_Right h2{
    display: none;
    margin: 0;
    margin-left: 30px;
    font-size: 25px;
    font-weight: 400;
    color: #00305b;
    text-transform: uppercase;    
}

.Functions_Right h2 b{
    font-weight: 600;
}

.Functions_Right_Con{
    width: calc(42.5vw - 30px);
    display: grid;
    margin-left: 30px;
    grid-template-columns: calc(50% - 20px) calc(50% - 20px);
    justify-content: space-between;
}

.Functions_Right_Item{
    display: flex;
    margin-top: 30px;
}

.Functions_Right_Box{
    height: 12px;
    width: 12px;
    background-color: #41b7c4;
    margin-right: 10px;
    margin-top: 5px;
}

.Functions_Right_Con h3{
    margin: 0;
    font-size: 16px;
    font-weight: 500;
    color: #00305b;
    text-decoration: underline;
    cursor: pointer;
}

.Functions_Right_Con p{
    margin: 0;
    margin-top: 10px;
    font-size: 14px;
    color: #0f4c85;
}

.Team{
    display: flex;
    height: 100vh;
    background-image: url('../assets/team.webp');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.Team span{
    height: 100%;
    width: 100%;
    background-color: #00315b72;
}

.Contact{
    height: auto;
    display: flex;
    background-image: url('../assets/hero.webp');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    position: relative;
}

.Contact_Ref{
    height: 0px;
    width: 10px;
    position: absolute;
    top: -70px;
}

.Contact_Left{
    min-width: 40%;
    display: flex;
    background-color: #00315be0;
}

.Contact_Left h2{
    margin: 0;
    margin-left: 17.5vw;
    margin-top: 70px;
    font-size: 30px;
    font-weight: 400;
    color: #fff;
    text-transform: uppercase;
}

.Contact_Left h2 b{
    font-weight: 600;
}

.Contact_Right{
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color: #00315bad;
    padding-top: 60px;
    padding-bottom: 70px;
    backdrop-filter: blur(10px);
}

.Contact_Right form{
    display: flex;
    flex-direction: column;
    width: calc(42.5vw - 30px);
    margin-left: 30px;
}

.Contact_Right form label{
    color: #fff;
    margin-top: 14px;
}

.Contact_Right form input{
    height: 35px;
    outline: none;
    border: none;
    margin-top: 8px;
    padding: 0 10px;
    font-size: 15px;
}

.Contact_Right_Con{
    display: flex;
    justify-content: space-between;
}

.Contact_Right_Con1{
    display: flex;
    flex-direction: column;
    width: calc(50% - 10px);
}

.Contact_Right form select{
    height: 35px;
    outline: none;
    border: none;
    margin-top: 8px;
    border-right: 10px solid #fff;
    padding: 0 10px;
    font-size: 15px;
}

.Contact_Right form button{
    height: 35px;
    border: 1px #fff solid;
    outline: none;
    color: #fff;
    font-size: 16px;
    background-color: transparent;
    margin-top: 40px;
    cursor: pointer;
}

.Service{
    height: fit-content;
    display: flex;
    justify-content: center;
    background-color: #fff;
}

.Service span{
    width: 65%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 70px 0;
}

.Service h2{
    margin: 0;
    color: #00305b;
    font-size: 30px;
    font-weight: 400;
    margin-top: 70px;
}

.Service p{
    margin: 0;
    margin-top: 20px;
    color: #00305b;
    text-align: center;
}

.Service_Con{
    display: grid;
    grid-template-columns: calc(33.33% - 15px) calc(33.33% - 15px) calc(33.33% - 15px);
    justify-content: space-between;
    width: 100%;
    margin-top: 10px;
}

.Service_Item{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
}

.Service_Item h3{
    margin: 0;
    color: #41b7c4;
    font-size: 18px;
    font-weight: 500;
    margin-top: 10px;
}

.Service_Img{
    height: 80px;
    width: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #41b7c416;
}

.Service_Item h4{
    margin: 0;
    font-size: 14px;
    margin-top: 10px;
    color: #00305b;
    text-align: justify;
    font-weight: 400;
}

.Map{
    display: flex;
}

.Map iframe{
    width: 100%;
    border: 0px;
    outline: 0px;
}

.Funct{
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Funct span{
    width: 65%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 70px;
}

.Funct img{
    aspect-ratio: 4;
    width: 100%;
    object-fit: cover;
    margin: 0;
}

.Funct h2{
    margin: 0;
    color: #00305b;
    font-size: 30px;
    font-weight: 400;
    margin-top: 50px;
}

.Funct p{
    margin: 0;
    margin-top: 20px;
    color: #00305b;
}

.Funct ol{
    text-align: left;
    margin: 0;
    padding: 0;
    margin-left: 20px;
}

.Funct li{
    margin-top: 10px;
}

@media screen and (max-width:1000px) {
    .Hero_Left h2{
        margin-left: 12.5vw;
    }
    
    .Hero_Left p{
        margin-left: 12.5vw;
    }

    .About span{
        width: 75%;
    }

    .Vision span{
        width: 75%;
    }

    .Premier span{
        width: 75%;
    }

    .Introduction span{
        width: 75%;
    }

    .Contact_Left h2{
        margin-left: 12.5vw;
    }

    .Contact_Right form{
        width: calc(47.5vw - 30px);
    }

    .Service span{
        width: 75%;
    }

    .Functions_Left h2{
        margin-left: 12.5vw;
    }

    .Funct span{
        width: 75%;
    }

    .Functions_Right_Con{
        width: calc(47.5vw - 30px);
    }
}

@media screen and (max-width:850px) {
    .Hero_Left h2{
        margin-left: 7.5vw;
    }

    .Hero_Left p{
        margin-left: 7.5vw;
    }

    .About span{
        width: 85%;
    }

    .Vision span{
        width: 85%;
    }

    .Premier span{
        width: 85%;
    }

    .Introduction span{
        width: 85%;
    }

    .Contact_Left h2{
        margin-left: 7.5vw;
    }

    .Contact_Right form{
        width: calc(52.5vw - 30px);
    }

    .Service span{
        width: 85%;
    }

    .Functions_Left h2{
        margin-left: 7.5vw;
    }

    .Funct span{
        width: 85%;
    }

    .Functions_Right_Con{
        width: calc(52.5vw - 30px);
    }
}

@media screen and (max-width:750px) {
    .Hero_Left{
        width: 50%;
    }
    .Functions_Left{
        width: 50%;
    }
    .Functions_Right_Con{
        width: calc(41vw - 20px);
        display: flex;
        flex-direction: column;
    }
    .Service_Con{
        grid-template-columns: calc(50% - 15px) calc(50% - 15px);
    }
}

@media screen and (max-width:500px) {
    .Hero_Left{
        width: 100%;
    }
    .Hero_Left h2{
        margin: 0 30px;
    }
    .Hero_Left p{
        margin: 0 30px;
    }
    .Functions_Left{
        display: none;
    }
    .Functions_Right{
        flex-direction: column;
    }
    .Functions_Right h2{
        display: block;
    }
    .Functions_Right_Con{
        width: calc(100% - 60px);
    }
    .Contact_Left{
        display: none;
    }
    .Contact_Right{
        width: 100vw;
    }
    .Contact_Right form{
        width: calc(100% - 60px);
        margin: 0 30px;
    }
    .Service_Con{
        display: flex;
        flex-direction: column;
    }
}