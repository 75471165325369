.Header{
    display: flex;
    justify-content: center;
    height: 80px;
    width: 100%;
    position: fixed;
    top: 0px;
    background-color: #fff;
    /* box-shadow: 0px 0px 5px #00315b70; */
    border-bottom: 1px #00315b21 solid;
    z-index: 300;
}

.Header_Con{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: 65%;
    display: flex;
}

.Header_Icon{
    display: none;
    cursor: pointer;
}

.Header h1{
    margin: 0;
    font-size: 21px;
    font-weight: 500;
    color: #00305b;
    font-family: "Sanchez", serif;
}

.Logo{
    height: 60px;
    width: 180px;
    background-image: url('../assets/logoblack.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

.Header_Link{
    text-decoration: none;
    color: #111;
    transition: 0.3s ease;
}
.Header_Link:hover{
    color: #41b7c4;
}

.Header_Link1{
    text-decoration: none;
    color: #00305b;
}

.Header ul{
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    cursor: pointer;
    user-select: none;
}

.Header ul li{
    font-size: 14px;
    margin-left: 30px;
    text-transform: uppercase;
    color: #111;
    transition: 0.3s ease;
}
.Header ul li:hover{
    color: #41b7c4;
}

.Footer{
    display: flex;
    justify-content: center;
    height: 80px;
    width: 100%;
    background-color: #fff;
    border-top: 1px #00315b21 solid;
}

.Footer span{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: 65%;
    display: flex;
}

.Footer p{
    margin: 0;
    font-size: 15px;
    color: #00305b;
    transition: 0.3s ease;
}

.Footer_Social{
    display: flex;
    align-items: center;
    gap: 15px;
}

.Header_Menu{
    width: 70vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    right: -70vw;
    top: 0px;
    background-color: #fff;
    z-index: 200;
    transition: 0.3s ease;
}

.Header_Menu_Open{
    right: 0px;
}

.Header_Menu ul{
    padding: 0;
    list-style: none;
}

.Header_Menu ul li{
    margin: 30px 0;
    text-align: center;
}

@media screen and (max-width:1000px) {
    .Header_Con{
        width: 75%;
    }
    .Footer span{
        width: 75%;
    }
}

@media screen and (max-width:850px) {
    .Header_Con{
        width: 85%;
    }
    .Footer span{
        width: 85%;
    }
}

@media screen and (max-width:750px) {
    .Header_Icon{
        display: flex;
    }
    .Header ul{
        display: none;
    }
}